import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const params = new URLSearchParams(window.location.search);

    if (params.has('cs')) {
      params.delete('cs');
      window.history.replaceState(null, null, `${window.location.pathname}?${params.toString()}`);
    }
  }
}
