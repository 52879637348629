import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect () {
    this.$storage = window.localStorage;
    this.$data = {
      deviceId: null,
      favorites: [],
      searchHistory: []
    };

    if (!this.$storage.$22lines) {
      this.$data.deviceId = this.generateDeviceId();
      this.persist();
    } else {
      this.$data = JSON.parse(this.$storage.$22lines);
      window.$22Lines.storage = this.$data;
    }

    if (!this.$data.deviceId || this.$data.deviceId === 'undefined') {
      this.$data.deviceId = this.generateDeviceId();
      this.persist();
    }

    this.$data.searchHistory = this.$data.searchHistory || [];

    this.scope.element.addEventListener('addSearchHistory', (e) => {
      this.addSearchHistory(e.detail.query);
    });
  }

  generateDeviceId () {
    return Math.random().toString(36).substr(2, 9);
  }

  persist () {
    this.$storage.$22lines  = JSON.stringify(this.$data);
    window.$22Lines.storage = this.$data;
  }

  toggleFavorite (id) {
    fetch(`/favorites/toggle?device_id=${this.$data.deviceId}&id=${id}`);

    let i;
    if ((i = this.$data.favorites.indexOf(id)) > -1) {
      this.$data.favorites.splice(i, 1);
    } else {
      this.$data.favorites.push(id);
    }

    this.persist();
  }

  isFavorite (id) {
    return this.$data.favorites.indexOf(id) > -1;
  }

  deviceIdLink (e) {
    e.preventDefault();
    window.location.href = e.currentTarget.getAttribute('href').replace('_device_id_', `${this.$data.deviceId}`);
  }

  addSearchHistory (query) {
    const history = this.$data.searchHistory.map((query) => {
      return query.toLowerCase();
    });

    if (query && query !== '' && history.indexOf(query.toLowerCase()) === -1) {
      this.$data.searchHistory.unshift(query);
      this.$data.searchHistory = this.$data.searchHistory.slice(0, 8);
      this.persist();
    }
  }
}
