import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  isLocalStorageAvailable () {
    try {
      window.localStorage.setItem('test', 'test');
      window.localStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }

  connect () {
    if (!this.isLocalStorageAvailable()) {
      return;
    }

    this.modal   = new bootstrap.Modal(this.element);
    this.$policy = null;

    if (window.localStorage.gunfinderCookies !== undefined) {
      if (window.localStorage.gunfinderCookies === 'true') {
        window.$22Lines.gtm();
      }

      return;
    }
    
    this.modal.show();
  }

  async loadPrivacyPolicy () {
    if (!this.$privacyPolicy) {
      const policy = await fetch('/privacy?vanilla=true', { headers: { 'X-Requested-With': 'XMLHttpRequest' } });
      this.element.querySelector('.cookie-consent-policy').innerHTML = await policy.text();
      this.$privacyPolicy = true;
    }
  }

  async more (e) {
    e.preventDefault();

    await this.loadPrivacyPolicy();
    document.getElementById('cookie-consent-more').classList.toggle('d-none');
    document.getElementById('cookie-consent-anchor').scrollIntoView();
  }

  accept () {
    window.localStorage.gunfinderCookies = true;
    this.modal.hide();
    window.$22Lines.gtm();
  }

  decline () {
    window.localStorage.gunfinderCookies = false;
    this.modal.hide();
  }
}
