import { Controller } from '@hotwired/stimulus';
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static targets = ['favoriteButton'];
  static values = { id: Number };

  connect () {
    useDispatch(this);

    this.evalFavorite();
  }

  isFavorite () {
    return this.appController.isFavorite(this.idValue);
  }

  get appController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('body'), 'app');
  }

  toggleFavorite (e) {
    e.preventDefault();
    
    this.appController.toggleFavorite(this.idValue);
    this.evalFavorite();

    if (!this.isFavorite()) {
      this.dispatch('unfavorited', { el: this.element });
    }
  }

  evalFavorite () {
    if (this.isFavorite()) {
      this.element.classList.add('favorite');
    } else {
      this.element.classList.remove('favorite');
    }
  }
}
