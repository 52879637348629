import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';

Rails.start();
window.Rails = Rails;

// window.Turbo = Turbo;

import { Dropdown, Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js';
window.bootstrap = { Dropdown, Modal };


const $init = (e) => {
  (() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      virtualUrl: e ? e.detail.url : window.location.href
    });
  })();
};

document.addEventListener('turbo:visit', $init);
$init(null); // script async does not fire turbo:*

import './controllers';
