import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { term: String };
  static targets = ['query'];

  connect() {
    this.element.addEventListener('submit', this.submit.bind(this));
  }

  submit (e) {
    if (this.queryTarget.value == '') {
      e.preventDefault();
    }
    
    this.queryTarget.value = `${this.termValue} ${this.queryTarget.value}`;
  }
}
