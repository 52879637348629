// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus';

const application = Application.start();

import AppController from './app_controller.js';
application.register('app', AppController);

import CookiesController from './cookies_controller.js';
application.register('cookies', CookiesController);

import CsController from './cs_controller.js';
application.register('cs', CsController);

import FavoritesController from './favorites_controller.js';
application.register('favorites', FavoritesController);

import PrefixSearchController from './prefix_search_controller.js';
application.register('prefix-search', PrefixSearchController);

import ProductController from './product_controller.js';
application.register('product', ProductController);

import RichContentController from './rich_content_controller.js';
application.register('rich-content', RichContentController);

import SearchAutocompleteController from './search_autocomplete_controller.js';
application.register('search-autocomplete', SearchAutocompleteController);

import DropdownController from './dropdown_controller.js';
application.register('dropdown', DropdownController);
