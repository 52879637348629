import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['noData'];

  connect () {
    this.$counter = this.element.querySelectorAll('.col').length;

    if (this.$counter === 0) {
      this.noDataTarget.classList.remove('d-none');
    }
  }

  disappear (e) {
    e.detail.el.remove();

    this.$counter -= 1;
    if (this.$counter === 0) {
      this.noDataTarget.classList.remove('d-none');
    }
  }
}
