import { Controller } from '@hotwired/stimulus';
import Autocomplete from './autocomplete.js';

export default class extends Controller {
  static values = { apiUrl: String };

  get appController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('body'), 'app');
  }

  connect () {
    const $this = this;

    this.autocomplete = new Autocomplete(this.element, {
      dropdownClass: 'w-100',
      data: [],
      threshold: 0,
      maximumItems: 10,
      onSelectItem ({ value }) {
        $this.element.value = value;
        $this.element.dispatchEvent(new Event('search-autocomplete.selected'));
        if ($this.element.closest('form')) {
          $this.element.closest('form').submit();
        }

        $this.appController.addSearchHistory(value);
      }
    });

    if (this.element.closest('form')) {
      this.element.closest('form').addEventListener('submit', () => {
        this.appController.addSearchHistory(this.element.value);
      });
    }

    this.renderSuggestions();
    this.element.addEventListener('input', this.renderSuggestions.bind(this));
  }

  async renderSuggestions (e) {
    const suggestions = await this.fetchSuggestions();

    const data = suggestions.map((suggestion, i) => {
      return {
        label: `<i class="bi bi-${suggestion.id ? 'search' : 'clock-history'}"></i> <span class="ms-1">${suggestion.query}</span>`,
        value: suggestion.query
      };
    });

    if (!e) {
      this.autocomplete.options.data = data;
    } else {
      this.autocomplete.setData(data);
    }
  }

  async fetchSuggestions () {
    const query = this.element.value;

    if (!query || query === '') {
      return this.appController.$data.searchHistory.map((query) => {
        return { query };
      });
    }

    const suggestions = await (await fetch(`/search/suggestions?query=${this.element.value}`, {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': Rails.csrfToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query })
      }))
      .json()
    ;
    
    return suggestions;
  }
}
